class ZIDXAccountUserEdit implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountUserEditContainer";
    }

    getMatchURL() {
        return "/account/users/edit";
    }

    render() {
        // console.log("user edit");
        window.ZIDXOptions.loadFunctions.push(function () {
            const $ = ZIDX.$;
            const user_id = $('input[name=user_id]').val();
            function check(value: string, i: string) {
                let $selectContainer = $(`#lead_assign_user_id_container${i}`);
                let $select = $(`#lead_assign_user_id${i}`);
                if (value != '') {
                    $selectContainer.hide();
                    $select.empty();
                    return;
                }

                let site_id = $(`input[name=site${i}]`).val();

                let obj = {
                    id: "ajaxSiteUsers",
                    method: "get",
                    postObj: {},
                    ignoreOldRequests: false,
                    callback: function (r: string) {
                        let users: any[] = $.parseJSON(r).users;
                        $select.empty();
                        $select.append(
                            $('<option/>').val(0).text('-- SELECT --')
                        );

                        function displayName(user: any) {
                            return `${user.firstName} ${user.lastName} (${user.email})`
                        }

                        users.sort((a, b) => {
                            if (a.self) {
                                return -1;
                            }
                            if (b.self) {
                                return 1;
                            }
                            return displayName(a).localeCompare(displayName(b));
                        });

                        users.forEach(user => {
                                if (user.id == user_id) {
                                    return;
                                }
                                const $option = $('<option/>').val(user.id);
                                if (user.self) {
                                    $option.text(`-- Myself --`)
                                } else {
                                    $option.text(displayName(user))
                                }
                                $select.append($option)
                            }
                        );
                        $selectContainer.show();
                    },
                    errorCallback: function (e: XMLHttpRequest) {
                        console.log(e.status);
                    },
                    url: '/account/sites/users?site_id=' + site_id
                };
                ZIDX.ajaxRequest(obj);
            }

            $('[zidx-none-unchecked-input]').click(function (this: any) {
                const $this = $(this);
                check($this.val(), $this.parent().attr('zidx-i'));
            })
                .each(function (i: any, element: any) {
                    const $this = $(element);
                    if ($this.is(':checked')) {
                        check($this.val(), $this.parent().attr('zidx-i'));
                    }
                })
            ;
        });
    }
}